<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="tax.id && organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="mr-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <p class="text-center font-weight-bolder font-large-1">
      {{ isUpdate ? $t('Update the Tax') : $t('Create a Tax Rule') }}
    </p>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="taxCreateVal"
      >
        <div id="general-information">
          <div class="py-2">
            <b-row>
              <b-col
                md="6"
              >
                <component
                  :is="fields[field].type"
                  v-for="field in ['label', 'value']"
                  :key="field"
                  v-model="tax[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </b-col>
              <b-col
                md="6"
              >
                <p class="text-left font-weight-bolder pl-3">
                  {{ $t('Status') }}
                </p>
                <component
                  :is="fields[field].type"
                  v-for="field in ['is_active']"
                  :key="field"
                  v-model="tax[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit || isDefault"
                />
              </b-col>
            </b-row>
          </div>
        </div>

        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings-taxes-list' })"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>

import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import store from '@/store'
import config from '../taxesConfig'

export default {
  name: 'TaxesForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    tax() {
      return this.$store.state[this.MODULE_NAME].taxesForm
    },
    taxClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].taxFormClone
    },
    isFormChanged() {
      console.log(JSON.stringify(this.tax))
      console.log('AAAAAAAA')
      console.log(this.taxClone)
      return JSON.stringify(this.tax) !== this.taxClone
    },
    isUpdate() {
      return this.tax.id
    },
    isDefault() {
      return this.$store.state[this.MODULE_NAME].taxesForm.is_default
    },
  },
  mounted() {
    if (!this.tax.id) this.isEdit = false
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.taxCreateVal.validate().then(success => {
        if (success) {
          const data = this.tax
          const tax = this.mappingFields(Object.keys(this.fields), data, {
            id: data.id,
            is_active: data.is_active ? 'true' : 'false',
            label: data.label,
            value: data.value,
          })
          this.sendNotification(this, tax, `${this.MODULE_NAME}/${this.isUpdate ? 'update' : 'create'}`)
            .then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.taxCreateVal)
        }
      })
    },

    clear() {
      this.$refs.taxCreateVal.reset()
      this.$router.push({ name: 'settings-taxes' })
    },
    loader() {
      this.$refs.taxCreateVal.reset()
      const smth = JSON.parse(this.taxClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-taxes'
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style>

</style>
