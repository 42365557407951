<template>
  <taxes-form
    ref="taxes-form"
    :is-create-form="true"
  />
</template>

<script>
import TaxesForm from '@/views/settings/general/taxes/components/TaxesForm.vue'

export default {
  name: 'TaxesCreate',
  components: { TaxesForm },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_TAX_FORM_CLONE`, {
      is_active: false,
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['taxes-form']) {
      this.$refs['taxes-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  setup() {
    const MODULE_NAME = 'settings-taxes'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
